export function accountsIsDtth(account) {
  return (
    account.info &&
    typeof account.info.url === 'string' &&
    account.info.url.startsWith(gtsDtth)
  );
}

/** URL to DTTHDon */
export const gtsDtth = 'https://gts.dtth.ch';

/** URL to DTTHDon settings */
export const gtsDtthSettings = 'https://gts.dtth.ch/settings';
